.upload-wrapper{
    display: flex;
    align-items: center;
  
    label {
      margin: 0;
    }
  
    input[type='file'] {
      display: none !important;
    }
  
    span {
      align-items: center;
      display: flex;
      margin-left: 5;
      font-size: 14;
      padding: 0 10px 0 0;
  
      &.cancel {
        color: red;
        border-left: 1px solid #bbbbbb;
        font-size: 12;
        font-weight: 500;
        cursor: pointer;
        padding-left: 5px;
      }
    }
}
.uploadResult{
    background-color: rgba(80, 159, 239, 0.03);
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  > div {
    display: flex;
    font-size: 14px;
    width: 100%;
  }

  .files {
    display: flex;
    flex-direction: column;
    flex: 1;

    > span {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}