.avatar-wrapper {
  height: inherit;
  div {
    margin: 0;
  }
  .dropdown-toggle.nav-link {
    display: flex;
    align-items: center;
  }
  .img-wrapper {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
    }
  }
  @media (min-width: 320px) and (max-width: 720px) {
    div {
      margin: 0;
    }
    .profile {
      display: none !important;
    }
  }
}
@media screen and (max-width: 500px) {
  .avatar-wrapper {
    .dropdown {
      .dropdown-menu {
        left: -80px !important;
      }
    }
  }
}
