.tabs-wrapper{
    padding: 20px 0;
    .nav-tabs {
      border: none;
    }
    .nav-tabs .nav-link {
      color: #bdbdbd;
      background-color: inherit;
      border: none;
      font-size: 14px;
      font-weight: 600;
      transition: color 250ms ease;
      img {
        opacity: 0.3;
        transition: opacity 300ms ease;
      }
  
      &.active {
        border-bottom: 3px solid var(--primary-color) !important;
      }
  
      &.active,
      &:hover {
        color: var(--primary-color);
  
        img {
          opacity: 1;
        }
      }
    }
}