.accordion-wrapper{
    padding: 0 20px;
    background: #f6f6f659;
  
    .accordion-toggle * {
      cursor: pointer;
    }
  
    .accordion-header {
      &:not(:last-child) {
        border-bottom: 0.3px solid #ececec;
      }
    }
}