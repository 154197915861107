.button-wrapper {
  // ${({ center }) => (center ? `display: flex; justify-content: center;` : '')};
  display: flex;
  justify-content: center;
  gap: 10px;
  // margin: 0px 5px;
  > div {
    width: -webkit-fill-available;
  }
  button {
    font-weight: 600;
    font-size: 13px;
    border-radius: 6px;
    border: none;
    height: 40px;
  }

  button.primary {
    background: var(--primary-color);
    color: #ffffff;
    font-size: 1em;
  }
  button.nude {
    background-color: #e0e0e0;
    color: #bdbdbd;
    outline: none;
  }
  button.secondary {
    background: var(--secondary-light);
    color: var(--primary-color);

    &.danger {
      background: #ffe4e4;
      color: #ff4747;
    }
  }

  button.outline {
    background: #ffffff;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  button.outline_danger {
    background: #ffffff;
    border: 1px solid #df374b;
    color: #df374b;
  }
  button.round {
    border-radius: 40px;
  }
}
.btn-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.btn-align-left {
  display: flex;
  justify-content: flex-start;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
@media screen and (max-width: 500px) {
  .button-wrapper {
    > div {
      width: -webkit-fill-available;
      button {
        width: -webkit-fill-available !important;
      }
    }
  }
}
