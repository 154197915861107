.page-wallet {
  .--cards {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    gap: 2%;
    margin-bottom: 1em;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    scroll-behavior: smooth;
    width: 100%;
    .wallet-card {
      flex: 1;
      border-radius: 16px;
      border: 1px solid #d3c1c1;
      background: white;
      scroll-snap-align: center;
      width: 33.3%;
      .card__container {
        display: flex;
        overflow-x: scroll;
      }
      .balance-nfo {
        padding: 20px;
        .top {
          display: flex;
          align-items: center;
          font-size: 16px;
          :nth-child(2) {
            cursor: pointer;
          }
          .icon-wrapper {
            cursor: pointer;
          }
          path {
            fill: black;
          }
        }
        .bottom {
          font-size: 28px;
        }
      }
      .fundWallet {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0px;
        padding: 10px;
      }
      .bank-nfo {
        border-radius: 0px 0px 16px 16px;
        color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 15px 10px;
        path {
          fill: black;
        }
        .detail {
          display: flex;
          align-items: center;
          gap: 10px;
          .bottom {
            display: flex;
            flex-direction: column;
          }
        }
        .copy {
          display: flex;
          align-items: center;
          gap: 3px;
          padding: 5px;
          background: rgba(255, 255, 255, 0.1);
          cursor: pointer;
          .icon-wrapper {
            margin: 0;
          }
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        font-weight: 600;
        font-size: 16px;
        :nth-child(2) {
          color: var(--primary-color);
          text-transform: uppercase;
          font-size: 14px;
        }
      }
      .payment-mthd {
        display: flex;
        flex-direction: column;
        height: 70%;
        padding: 10px;
        .top {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(98, 98, 98, 1);
        }
        .bottom {
          display: flex;
          justify-content: flex-end;
        }
      }
      .setting {
        display: flex;
        padding: 15px;
        justify-content: space-between;
        .top {
          display: flex;
          align-items: center;
        }
      }
      .notification {
        padding: 0px 15px;
      }
    }
    //   .card {
    //     height: 100%;
    //   }
    //   .card-footer {
    //     background: none;
    //     border: none;
    //   }
    // }
    // > div {
    //   height: auto;
    // }
    @media (min-width: 320px) and (max-width: 750px) {
      .wallet-card {
        min-width: 100%;
      }
    }
  }
  .--cards::-webkit-scrollbar {
    display: none;
  }
  .account {
    display: flex;
    > div {
      flex: calc(1 / 3);
      //gap: 2%;
      box-shadow: none;
      border-radius: 4px;
      font-weight: 300;
      font-size: 14px;
      //width: 100%;
      > div {
        background: #f4f4f2;
        margin-right: 18px;
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px;
        border-radius: 5px;
        > div {
          display: flex;
          flex-direction: column;

          span:first-of-type {
            font-size: 12px;
            color: #736c6c;
            margin-bottom: 7px;
          }
          span:last-of-type {
            font-size: 14px;
            color: #505050;
            font-weight: 400;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 750px) {
    .account {
      display: block;
    }
  }
  .transactions-table {
    margin-top: 3em;
  }
}

.success__group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  .header {
    font-weight: bold;
    font-size: 22px;
  }
  .subtitle,
  .message {
    font-weight: 300;
    text-align: center;
  }
  .opt {
    display: flex;
    align-items: center;
    color: var(--primary-color);
  }
}

.fundwallet-wrapper {
  display: flex;
  justify-content: space-between;
  > div {
    padding: 15px;
  }
  .left {
    background: rgba(241, 241, 241, 0.42);
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .error {
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: red;
  }
}
