.header-wrapper {
  height: 64px;
  background: #ffffff;
  padding: 0px 40px;
  // padding-left: ${({ isMobile: mobile }) => (mobile ? '20px' : '40px')};
  // padding-right: ${({ isMobile: mobile }) => (mobile ? '20px' : '40px')};
  position: sticky;
  top: 0;
  z-index: 999;
  // box-shadow: 0px 8px 20px 0px rgb(51 64 84 / 3%);
  display: flex;
  align-items: center;

  nav {
    background: #ffffff;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .hamburger {
    display: none;
  }
  @media (min-width: '320px') and (max-width: '768px') {
    padding: 0px 20px;
    .hamburger {
      display: flex;
    }
    nav {
      display: flex;
      justify-content: space-between !important;
    }
    nav > * {
      width: 33%;
      flex: 1;
      flex-basis: unset !important;
    }
    .dropdown-toggle {
      padding: 0 !important;
    }

    .navbar-brand h4 {
      display: flex;
      justify-content: center;
    }
    .align-items-center .search {
      display: none;
    }
  }
}
