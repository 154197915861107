.card-header-wrapper{
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    color: #111111;
    margin: 20px 0;
    letter-spacing: 0.25px;
}
.card-wrapper{
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  //margin-bottom: 15px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  .card {
    height: 100%;
    border: none;
    background: inherit;
  }

  .card-title {
    color: #505050;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }

  .card-footer {
    border: none;
    background: none;
    display: flex;
    justify-content: space-between;
    color: #828282;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
  }

  .card-text {
    display: flex;
    gap:12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;

    > * {
      width: 100%;
    }
  }
}