.icon-wrapper{
    margin: 0px 10px;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      svg {
        path {
        }
      }
    
      .logo svg {
        background: pink !important;
      }
}