.finalize-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .header {
      font-weight: bold;
      font-size: 22px;
    }
    .subtitle {
      font-weight: 300;
      text-align: center;
    }
    .opt {
      display: flex;
      align-items: center;
      color: var(--primary-color);
    }
}