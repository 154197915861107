.contact-wrapper{
    .heading{
        display:flex;
        justify-content: space-between;
        align-items: center;
        .title{
          font-size: 14px;
          color:#505050;
        }
    }
}

.overflow--initial {
  overflow-x: initial !important;
}
.edit-contact-wrapper{
  display: flex;
  flex-direction: column;
  gap: 30px;
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}