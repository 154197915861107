.profile-wrapper {
  background: #ffffff;
  padding: 20px 0px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  height: fit-content;

  .avatar {
    display: flex;
    justify-content: center;
    height: 400px;
    .avatar-options {
      display: flex;
      justify-content: center;
      gap: 20px;
      .delete-option {
        display: flex;
        color: var(--primary-color);
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
