$font__size--base: 1rem;
$breakpoints: (
  small: 320px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1600px,
  mid: 910px,
);

:root {
  // --primary-color: red;
  // --secondary-color: orange;
  --primary-color: #17568b;
  --secondary-color: #3f739f;
  --secondary-light: #dce8f4;
  --secondary-light-2: rgba(80, 159, 239, 0.08);
  --white-color: #ffffff;
  --primary-grey: #333333;
  --primary-black: #000000;
  --placeholder-grey: #736c6c;
  --primary-font: 'IBM Plex Sans', sans-serif;
  --off-white: #f4f4f2;
  --lemon-green: #7ec544;
  --success-green: #63b67f;
  --lighter-grey: #828282;
}

*::before,
*::after,
* {
  box-sizing: border-box;
}
html {
  width: 100vw;
  height: 100vh;
  font-size: 14px;
}

html,
body {
  padding: 0em;
  margin: 0em;
  font-size: 14px;
  font-weight: 400;
}
body {
  font-family: var(--primary-font);
  width: 100%;
  height: 100vh;
}
#root {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
p {
  // Font minimum, preferred and maximum value
  font-size: clamp(var(--min), var(--val), var(--max));
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font);
}
// Font size variables
h1 {
  --min: 2em; // minimum value
  --val: 5vw; // preferred value = 5% viewport width
  --max: 3em; // maximum value
}
h2 {
  --min: 1.5em; /* minimum value */
  --val: 4vw; /* preferred value = 4% viewport width */
  --max: 2.25em; /* maximum value */
}
h3 {
  --min: 1.4em; /* minimum value */
  --val: 3.5vw; /* preferred value = 4% viewport width */
  --max: 2.05em; /* maximum value */
}
h4 {
  --min: 1.1em; /* minimum value */
  --val: 2.5vw; /* preferred value = 4% viewport width */
  --max: 1.4em; /* maximum value */
}
h5 {
  --min: 1em; /* minimum value */
  --val: 2.3vw; /* preferred value = 4% viewport width */
  --max: 1.2em; /* maximum value */
}
p {
  --min: 0.7em; // minimum value
  --val: 1.5vw; // preferred value = 2.5% viewport width
  --max: 1em; // maximum value
}
