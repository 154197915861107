.prices-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  thead {
    th {
      color: var(--primary-color) !important;
    }
  }
  tbody td div:first-child{
      padding-bottom: 0px !important;
  }
}
