@import '../utilities/variables';
.auth__container {
  background: rgba(254, 254, 254, 0.96);
}
.auth--container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  padding: 3rem;
  .right-side {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 5px 55px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    height: 100%;
    .home-link-img {
      display: none;
    }
    > div {
      width: 100%;
      height: inherit;
      padding: 3rem;
    }
    .justify-content-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
    }
    .signup-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      padding-top: 4em;
      height: 95%;
      flex: 1;
      .progress-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 2rem;
        .bar {
          width: 28%;
          position: relative;
          height: 100%;
          .line {
            background: #d3dce6;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
          }
          .status-left {
            position: absolute;
            top: -10px;
            bottom: 0;
            left: 25px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: var(--success-green);
            text-align: center;
            color: #ffffff;
            &-outline {
              background: #ffffff;
              outline: 2px solid #d3dce6;
              position: absolute;
              top: -10px;
              bottom: 0;
              left: 25px;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              text-align: center;
              color: #bdbdbd;
            }
          }
          .status-right {
            position: absolute;
            top: -10px;
            bottom: 0;
            right: 25px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: var(--success-green);
            text-align: center;
            color: #ffffff;
            &-outline {
              background: #ffffff;
              outline: 2px solid #d3dce6;
              position: absolute;
              top: -10px;
              bottom: 0;
              right: 25px;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              text-align: center;
              color: #bdbdbd;
            }
          }
        }
      }
    }
    .login-container {
      padding-top: 2em;
      .extra-actions {
        padding: 2rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .forgot-password p {
          color: #505050;
          font-weight: 400;
          span {
            margin-left: 6px;
          }
        }
        .need-help p {
          color: #505050;
          font-weight: 400;
          span {
            margin-left: 6px;
          }
        }
      }
    }
    .button-wrapper {
      justify-content: flex-start;
    }
  }
  .left-side {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .page-details {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      h5 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #505050;
        margin-top: 15px;
        padding-right: calc(100% / 4);
        padding-bottom: 20px;
        line-height: 1.4;
      }
      h1 {
        font-size: 2.6em;
        font-weight: 900;
        letter-spacing: 0.05px;
        color: #071b2b;
        line-height: 1.5;
      }
      .page-img {
        width: 90%;
        height: auto;
        margin: auto;
        display: block;
      }
    }
  }
  // @media(min-width:'medium'){
  //         // .left-side{
  //         //     display: none;
  //         // }
  // }
}
.verify-account-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-image: url('../../icons/lock.svg');
  background-repeat: no-repeat;
  background-position-y: center;
}
@media screen and (max-width: 700px) {
  .auth--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0.5rem;
    .left-side {
      display: none;
    }
    .right-side {
      .home-link-img {
        display: block;
        margin-bottom: 20px;
      }
      .signup-container .progress-container .bar {
        width: 50% !important;
        position: relative;
        height: 100%;
      }
    }
  }
}
