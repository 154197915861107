input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label.Mui-focused {
  color: #01aae4 !important;
}
.input-wrapper {
  padding-bottom: 20px;
  font-weight: 300;
  // height: ${(props) => props.height};
  label {
    font-size: 14px;
    font-weight: 300;
    padding-left: 3px;
  }

  ul li {
    font-size: 14px;
    font-weight: 300;
  }

  input,
  select,
  textarea {
    border-radius: 6px;
    font-weight: 300;
    cursor: pointer;
  }

  > div {
    width: 100%;
  }

  .MuiSelect-select {
    box-sizing: border-box;
    height: 48px;

    &:focus {
      border-radius: 6px;
    }
  }

  .search .MuiOutlinedInput-root {
    height: 38px;
  }
  .MuiOutlinedInput-multiline {
    height: auto !important;
  }

  .MuiCheckbox-root {
    padding-left: 0;
    .MuiTypography-body1 {
      padding: 10px;
    }
  }
  .hint {
    font-size: 10px;
    margin: 8px 0px;
    color: #060505 !important
    ;
  }
}
.search {
  height: 36px;
}
.otpInput {
  input {
    text-align: center;
  }
}
.otp_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  grid-column: "none";
  grid-row: "none";
  input {
    height: "72px";
    width: "130%";
    padding: "0rem 1rem";
    border: "1px solid #BDBDBD";
    border-radius: 5px;
    font-size: 1.6rem;
    margin: 0;
    width: "100%";
    height: 4.8rem;
    background: var(--off-white);
    color: rgba(22, 16, 11, 0.38);
    &::placeholder {
      color: rgba(22, 16, 11, 0.38);
      font-size: 1.2rem;
    }
  }
}
