@import '../utilities/variables';
.filter-wrapper {
  background-color: white;
  padding: 10px;
  width: 100%;
  color: #505050;
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    padding-top: 0;
  }
  .MuiTextField-root.MuiFormControl-marginNormal {
    margin-top: 10px;
  }
  .MuiSelect-iconOutlined {
    right: 7px;
    display: none !important;
  }
  .filterButton {
    button {
      width: 100px;
    }
  }
  .filterType {
    width: 100%;
    position: relative;
    .caret {
      position: absolute;
      right: -5px;
      top: 15px;
    }
    .dwpXEl {
      margin: 0px 5px !important;
    }
    .fJWLSK {
      margin-bottom: 0;
    }
  }
  .limit_filter{
    width: 100px;
  }
  .filterDate {
    display: flex;
  }
  .title {
    width: fit-content;
  }
  .other-filter-wrapper {
    font-size: 16px;
    align-items: center;
    align-content: center;
    width: 60vw;
    display: flex;
    gap: 10px;
  }
  .filter{
    display: flex;
    justify-content: space-between;
  }
  div {
    padding-bottom: 0;
  }
  .date {
    padding: 13px;
    border: 1px solid #bdbdbd;
    font-size: 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .activity {
    padding: 15px;
    border: 1px solid #bdbdbd;
    font-size: 12px;
    border-radius: 6px;
  }
  @media (min-width: 320px) and (max-width: 720px) {
    .filter {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }
    .filterType {
      margin-bottom: 10px;
    }
    .filterType button {
      width: 100% !important;
    }
  }
}

.gXmJBZ {
  margin-top: 0;
  margin-bottom: 0 !important;
}
