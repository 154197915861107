.table-wrapper {
  height: inherit;
  border: none;
  .table-responsive {
    overflow-x: scroll;
  }
  td {
    vertical-align: middle;
  }
  .table,
  thead {
    border: none;
    border-bottom: 2px solid #dee2e6;
  }
  .table::-webkit-scrollbar {
    display: none;
  }
  .table-striped .primary tr:nth-of-type(odd) {
    background: var(--secondary-light-2);
  }
  .table-striped .primary td {
    border-top: none !important;
  }
  .table-striped .secondary tr:nth-of-type(odd) {
    background: #ffffff;
  }
  thead {
    background: #f2f2f2;
    tr th:last-child {
      display: flex;
      justify-content: flex-end;
    }
    th {
      font-weight: bold;
      font-size: 14px;
      color: #505050;
      border: none !important;
      padding: 20px 10px;
    }
  }
  tbody {
    background: #ffffff;

    tr {
      &:first-child td {
        border-top: none;
      }

      &:hover {
        background-color: #f4f4f4;
      }

      td {
        padding: 14px;
        font-size: 12px;
        font-weight: 500;
        &:last-of-type {
          text-align: right;
        }
      }
    }
    @media (min-width: 320px) and (max-width: 720px) {
      .tr .td {
        font-size: 10px;
      }
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttons {
      display: flex;
      gap: 10px;
    }
    .limit {
      margin: 5px 10px;
      padding: 5px 15px;
      border: 1px solid #bdbdbd;
      border-radius: 6px;
    }
    @media (min-width: 320px) and (max-width: 720px) {
    }
  }
  .nav-link {
    padding: 0px;
  }
}
@media screen and (max-width: 500px) {
  .table-wrapper {
    height: inherit;
    border: none;
    .table-responsive {
      overflow-x: scroll;
      .table {
        border: none;
        border-bottom: 2px solid #dee2e6;
        td {
          .td-width-100 {
            width: 100px;
          }
          .td-width-150 {
            width: 150px;
          }
        }
      }
    }
  }
}
