.checkbox-wrapper {
  display: flex;
  flex: 1;
  align-items: baseline;
  margin-bottom: 1.1rem;

  label {
    margin-left: 2rem;
    color: rgba(22, 16, 11, 0.87);
    font-size: 1.1rem;
  }
  input[type='checkbox'] {
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid var(--primary-color);
    &:checked {
      background: #000;
    }
  }
}
