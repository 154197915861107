.graph-reposition {
  position: absolute;
  left: -15px;
}

.error {
  color: red;
  display: flex;
  justify-content: center;
  font-size: 12px;
}
