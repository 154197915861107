.options-wrapper {
  height: inherit;

  .dropdown-toggle::after {
    display: none !important;
    content: none;
  }
  .option {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
    }
  }
  @media (min-width: 320px) and (max-width: 720px) {
    .dropdown-toggle::after {
      display: none !important;
    }
  }
}
