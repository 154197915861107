.airtime-wrapper {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .exceed {
    border-left: 2px solid red;
    padding: 10px 15px;
    background: rgba(80, 159, 239, 0.03);
    font-size: '12px';
    color: '#505050';
    margin-bottom: '20px';
    .nfo {
      font-weight: bold;
      font-size: 14px;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  .recpt-wrapper {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
  }
  .error {
    font-size: 14px;
    color: red;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
}
.data-wrapper {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .exceed {
    border-left: 2px solid red;
    padding: 10px 15px;
    background: rgba(80, 159, 239, 0.03);
    font-size: 12px;
    color: #505050;
    margin-bottom: 20px;
    .nfo {
      font-weight: bold;
      font-size: 14px;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  .loading{
    display: flex;
    justify-content: center;
    margin: 10px auto;
    width: 100px;
  }
  .shw-operator{
    background-color: rgba(80, 159, 239, 0.03);
    margin-top: 5px;
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .error {
    font-size: 14px;
    color: red;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
}
.paybill-wrapper {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .billers {
    display: flex;
    gap: 30px;
    overflow: scroll;
    margin: 20px 0px;
    .biller-card {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
      cursor: pointer;
      .content {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        span {
          color: var(--primary-color);
          font-weight: normal;
        }
      }
    }
    #active {
      border: 2px solid var(--primary-color);
    }
  }
  .billers::-webkit-scrollbar {
    display: none;
  }
  .info-sec {
    .header {
      font-size: 20px;
      color: #bdbdbd;
    }
  }
}
.cabletv-wrapper,
.electricity-wrapper {
  @media (min-width: 320px) and (max-width: 720px) {
    .submit {
      display: flex;
      gap: 20px;
    }
  }
}
