.dashboard-wrapper {
  .quick {
    display: flex;
  }
  .data {
    min-width: 150px;
  }
  .analytics::-webkit-scrollbar,
  .activities::-webkit-scrollbar {
    display: none;
  }
  .analytics {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    scroll-behavior: smooth;
    flex-wrap: nowrap;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    > .card {
      padding: 10px;
      height: 100%;
      border: none;
    }
    .col {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .activities {
    height: 400px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .card {
      height: 100%;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

.navbar-brand {
  h4 {
    font-size: 25px;
    color: #071b2b;
  }
}
