.sidemenu-wrapper {
  width: 300px !important;
  background: var(--primary-color) !important;
  color: #ffffff !important;
  height: 100vh !important;
  display: flex;
  flex-direction: column;

  li * {
    color: #ffffff !important;
    font-weight: 300 !important;
    font-size: 16px !important;
  }
  .side-navigation-panel {
    flex-grow: 1;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none !important;
  }
  .side-navigation-panel::-webkit-scrollbar {
    display: none;
  }

  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option {
    padding: 20px;
  }
  .side-navigation-panel-select-option {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 32px !important;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option
    .side-navigation-panel-select-inner-option-wrap
    .side-navigation-panel-select-inner-option-text {
    font-size: 13px !important;
    line-height: 1.25rem;
    margin-right: 0;
    margin-left: 20px !important;
  }

  .side-navigation-panel-select-inner-option {
    margin-top: 1rem !important;
    padding-left: 60px !important;
    height: 32px !important;
  }

  .side-navigation-panel-select-option,
  .side-navigation-panel-select-inner-option {
    background: var(--primary-color) !important;
    border-color: var(--primary-color);
    border-left-width: 3px !important;
    color: #ffffff !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    height: 24px !important;
    transition: all 250ms ease !important;
  }
  .side-navigation-panel-select-option-selected,
  .side-navigation-panel-select-inner-option-selected {
    span *,
    & {
      font-weight: 600 !important;
    }
  }

  .side-navigation-panel-select-option-selected,
  .side-navigation-panel-select-inner-option-selected,
  .side-navigation-panel-select-option:hover,
  .side-navigation-panel-select-inner-option:hover {
    background: var(--primary-color) !important;
    border-color: #d69797 !important;
    border-left-width: 3px !important;
  }
  .side-navigation-panel-select-option-icon {
    margin-left: auto !important;
  }
  .closeSideMenu {
    display: none;
  }
  @media (min-width: "320px") and (max-width: "768px") {
    .closeSideMenu {
      display: flex;
      justify-content: flex-end;
      margin-bottom: -15px;
    }
  }
}
@media (min-width: "320px") and (max-width: "768px") {
  #showSideMenu {
    position: absolute;
    z-index: 99999;
    left: 0;
    transition: left 300ms ease;
  }
  #hideSideMenu {
    position: absolute;
    z-index: 99999;

    left: -350px;
    transition: all 300ms ease;
  }
}

.side-navigation-panel-select-option {
  .icon__svg {
    svg {
      path {
        fill: #ffffff75;
        fill-opacity: 1;
      }
    }
  }
}

.side-navigation-panel-select-option.side-navigation-panel-select-option-selected {
  .icon__svg {
    svg {
      path {
        fill: #ffffff;
        fill-opacity: 1;
      }
    }
  }
}
 