.allcampaign-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.campaign-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  padding: 30px;
  .upload-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    font-size: 12px;
    .contact {
      font-weight: 500;
      display: flex;
      align-items: center;
      color: var(--primary-color);
      cursor: pointer;
    }
    @media (min-width: 320px) and (max-width: 720px) {
      .upload-wrapper {
        font-size: 12px;
      }
      .contact {
        display: none;
      }
    }
  }
}

.campaign__edit {
  display: flex;
  flex-direction: column;
  .header {
    margin-bottom: 50px;
  }
  .sub-header {
    display: flex;
    gap: 40px;
    margin: 40px 0px;
  }
  .title {
    background: #fff;
    padding-bottom: 50px;
    padding-top: 20px;
    padding-left: 20px;
  }
  .left {
    color: #828282;
    font-size: 16px;
    margin: 0px 5px;
  }
 
}
.campaign {
  &__no--sender{
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    .title{
      text-align: center;
      margin: 15px 0px;
    }
    .header {
      font-size: 24px;
      font-weight: bold;
    }

  }
}
.center-container{
  display: flex;
  justify-content: center;
}