.switch-tab{
  background: var(--secondary-light);
  border-radius: 50px;
  width: 200px;
  height: 39px;
  display: flex;
  justify-content: space-around;
  .tab{
    text-align: center;
    padding: 10px;
    position: relative;
    flex:1;
    cursor: pointer;
    color: var(--primary-color);
    border-radius: inherit;
    transition: all 300ms ease;
    &.active{
      background: var(--primary-color);
      color: #ffffff;
    }
  }
}
